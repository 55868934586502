import ThemeProvider from './utils/theme/theme';
import Button from './components/Button';
import VerticalContainer from './components/VerticalContainer';
import ProfilePicture from './components/ProfilePicture';
import Style from 'styled-components';
import Label from './components/Label';
import { ColorMode, ColorStyle, FontStyle, FontType, LinkType } from './utils/types/types';

const App = () =>
    <ThemeProvider>
        <VerticalContainer>
            <a href="https://creastel.com" target="_blank" rel="noreferrer">
                <Logo alt="Logo Creastel" src="./assets/logos/large.png" />
            </a>
            <ProfilePicture iconPath='./assets/profile/picture.png' />
            <Label text='name' type={FontType.body} fontStyle={FontStyle.header} color={ColorStyle.title} mode={ColorMode.dark} align='center' />
            <Label text='let_me_present_you_creastel' margin="12px 16px 48px 16px" type={FontType.body} fontStyle={FontStyle.button} color={ColorStyle.button} mode={ColorMode.dark} align='center' />
            <Button key={LinkType.website} type={LinkType.website} url='https://creastel.com' />
        </VerticalContainer>
    </ThemeProvider>

const Logo = Style.img<{}>`
  height: 32px;
  margin: 0 0 32px 0; 
`;

export default App;
