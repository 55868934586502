import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// wording sheet key = 1W8wL4ujBx2CFGcAaw2h6sFpG8gikxMabDD6Eat17vMQ
// wording link = https://docs.google.com/spreadsheets/d/1W8wL4ujBx2CFGcAaw2h6sFpG8gikxMabDD6Eat17vMQ/edit?usp=sharing
i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources: {
        fr: {
            translations: {
                "name": "Grégoire Thibaud",
                "let_me_present_you_creastel": "Co-fondateur de l'agence de création numérique <strong>Creastel</strong>.",
                "see_website.button": "Découvrir Creastel",
            }
        }, en: {
            translations: {
                "name": "Grégoire Thibaud",
                "let_me_present_you_creastel": "Co-founder of the digital agency <strong>Creastel</strong>.",
                "see_website.button": "Discover Creastel",
            }
        }
    },
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
